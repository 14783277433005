import './App.css';
import Hero from "./components/Heros/Hero.jsx"
import Programs from "./components/Programs/Programs.jsx"
import Reasons from "./components/Reasons/Reasons"
import Plans from "./components/Plans/Plans.jsx"
import Testimonials from './components/testimonials/Testimonials';
import Join from "./components/Join/Join.jsx"
import Footer from './components/Footer/Footer';

function App() {
  return (
    <div className="App">
          <Hero />
          <Programs />
          <Reasons />
          <Plans />
          <Testimonials />
          <Join />
          <Footer />
    </div>
  );
}

export default App;
